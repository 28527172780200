import React, { useEffect } from "react"
import Footer from "./Footer";
import Header  from "./Header"
// import SEO from "./Seo";

const Layout = ({ children }) => {
  useEffect(() => {
    const cursor = document.querySelector(".cursor")

    document.addEventListener("mousemove", e => {
      cursor.setAttribute(
        "style",
        "top: " + (e.pageY - 10) + "px; left: " + (e.pageX - 5) + "px;"
      )
    })

    document.addEventListener("click", () => {
      cursor.classList.add("expand")
      setTimeout(() => {
        cursor.classList.remove("expand")
      }, 200)
    })
  }, [])

  return (
    <>
      <div className="cursor"></div>
      <Header></Header>
      {/* <SEO /> */}
      <div className="flex flex-col min-h-screen bg-white">{children}</div>
      <Footer />
    </>
  )
}

export default Layout
